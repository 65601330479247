import React from 'react';
// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const BqDashboardItems = {
  id: 'bqDashboard',
  title: 'Big Query Dashboard',
  type: 'group',
  children: [
    {
      id: 'syncDashboard',
      title: 'Sync Dashboard',
      type: 'item',
      url: '/bq-dashboard',
    },
    {
      id: 'googleSheets',
      title: 'Google Sheets',
      type: 'item',
      url: '/bq-dashboard/google-sheets',
    },
    {
      id: 'uniwareReports',
      title: 'Uniware Reports',
      type: 'item',
      url: '/bq-dashboard/uniware-reports',
    },
    {
      id: 'shopifySyncs',
      title: 'Shopify Syncs',
      type: 'item',
      url: '/bq-dashboard/shopify',
    },
  ]
};

export default BqDashboardItems;
