import React from 'react';

import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout/index';
import RepositoryLayout from '../layout/RepositoryLayout';
import ShopifyLayout from '../layout/ShopifyLayout';
import BqDashboardLayout from '../layout/BqDashboardLayout';
import ShopifyCollectionsLayout from '../layout/ShopifyCollectionsLayout';
import Cms from '../pages/Cms';
import CmsJson from '../pages/cms-json';
import BlogEdit from '../pages/blog-edit';


// render dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));
const AnnouncementBar= Loadable(lazy(() => import('../pages/shopifyCms/AnnouncementBar')));
const MainBanner= Loadable(lazy(() => import('../pages/shopifyCms/MainBanner')));
const TestimonialsBar=Loadable(lazy(() => import('../pages/shopifyCms/TestimonialsBar')));
const BrandingBanner =Loadable(lazy(() => import('../pages/shopifyCms/BrandingBanner')));
const BrandingSection =Loadable(lazy(() => import('../pages/shopifyCms/BrandingSection')));
const BlogSection =Loadable(lazy(() => import('../pages/shopifyCms/BlogSection')));
const Pdp =Loadable(lazy(() => import('../pages/shopifyCms/Pdp')));
const NavBar =Loadable(lazy(() => import('../pages/shopifyCms/NavBar')));
const CollectionArray =Loadable(lazy(() => import('../pages/shopifyCms/CollectionArray')));
const CollectionGrid =Loadable(lazy(() => import('../pages/shopifyCms/CollectionGrid')));
const FeaturedCollection =Loadable(lazy(() => import('../pages/shopifyCms/FeaturedCollection')));

const SalesTable = Loadable(lazy(() => import('../pages/repository/salesTable')));
const ReturnsTable = Loadable(lazy(() => import('../pages/repository/returnsTable')));

// shopify collections components
const Collections = Loadable(lazy(() => import('../pages/ShopifyCollections/Collections')));

// lazy load BQ dashboad routes
const SyncDashboard = Loadable(lazy(() => import('../pages/bq-dashboard/syncDashboard')));
const GoogleSheets = Loadable(lazy(() => import('../pages/bq-dashboard/googleSheets')));
const UniwareReports = Loadable(lazy(() => import('../pages/bq-dashboard/uniwareReports')));
const ShopifySyncs = Loadable(lazy(() => import('../pages/bq-dashboard/shopifySyncs')));

// render - utilities
const UploadAds = Loadable(lazy(() => import('../pages/Upload-pages/UploadAds')));
const UploadAdsets = Loadable(lazy(() => import('../pages/Upload-pages/UploadAdset')));
const SwitchStatusCampaignAndAdsetCsvUpload=Loadable(lazy(()=>import('../pages/Upload-pages/SwitchStatusCampaignAndAdsetCsvUpload')))
const Products = Loadable(lazy(() => import('../pages/Product-dashboard/Products')));
const Inventory = Loadable(lazy(() => import('../pages/Inventory/Inventory')));
const CommerceManagerCatalogueFeed = Loadable(lazy((() => import('../pages/Upload-pages/CommerceManagerCatalogueFeed'))));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: 'cam-manager',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'ads',
      element: <UploadAds />
    },
    {
      path: 'adsets',
      element: <UploadAdsets />
    },
    {
      path: 'commerceManagerCatalogueFeed',
      element: <CommerceManagerCatalogueFeed />
    },
    {
      path: 'SwitchStatusCampaignAndAdsetCsvUpload',
      element: <SwitchStatusCampaignAndAdsetCsvUpload />
    },
    {
      path: 'products',
      element: <Products />
    },
    {
      path: 'inventory',
      element: <Inventory />
    }
  ]
};

const RepositoryRoutes = {
  path: 'repository',
  element: <RepositoryLayout />,
  children: [
    {
      path: 'sales',
      element: <SalesTable />
    },
    {
      path: 'returns',
      element: <ReturnsTable />
    }
  ]
};

const BqDashboardRoutes = {
  path: 'bq-dashboard',
  element: <BqDashboardLayout />,
  children: [
    {
      path: '',
      element: <SyncDashboard />
    },
    {
      path: 'google-sheets',
      element: <GoogleSheets />
    },
    {
      path: 'uniware-reports',
      element: <UniwareReports />
    },
    {
      path: 'shopify',
      element: <ShopifySyncs />
    }
  ]
};

const ShopifyCmsRoutes = {
  path: 'shopifyCms',
  element: <ShopifyLayout />,
  children:[
    // {
    //   path:'announcementBar',
    //   element:<AnnouncementBar/>
    // },
    {
      path:'mainBanner',
      element:<MainBanner/>
    },
    // {
    //   path:'testimonialsBar',
    //   element:<TestimonialsBar/>
    // },
    // {
    //   path:'brandingBanner',
    //   element:<BrandingBanner/>
    // },
    // {
    //   path:'brandingSection',
    //   element:<BrandingSection/>
    // },
    // {
    //   path:'blogSection',
    //   element:<BlogSection/>
    // }  ,
    // {
    //   path:'pdp',
    //   element:<Pdp/>
    // }   ,
    {
      path:'navBar',
      element:<NavBar/>
    } ,
    {
      path:'collectionArray',
      element:<CollectionArray/>
    } ,
    {
      path:'collectionGrid',
      element:<CollectionGrid/>
    } ,
    // {
    //   path:'featuredCollection',
    //   element:<FeaturedCollection/>
    // } 
     ]
};

const ShopifyCollectionRoutes = {
  path: 'shopify-collections',
  element: <ShopifyCollectionsLayout />,
  children: [
    {
      path: 'collections',
      element: <Collections />
    }
  ]
};

const CmsRoutes = {
  path: "cms", 
  element: <Cms />
}

const CmsJsonRoutes = {
  path: "cms-json", 
  element: <CmsJson />
}

const BlogEditRoutes = {
  path: "blog-edit", 
  element: <BlogEdit />
}

export { MainRoutes, RepositoryRoutes, BqDashboardRoutes ,ShopifyCmsRoutes, ShopifyCollectionRoutes, CmsRoutes, CmsJsonRoutes, BlogEditRoutes};
