import React from 'react';
// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const ShopifyCollectionItems = {
  id: 'shopifyCollections',
  title: 'Shopify Collections',
  type: 'group',
  children: [
    {
      id: 'shopifyCollection',
      title: 'Shopify Dashboard',
      type: 'item',
      url: '/shopify-collections/collections',
    }
  ]
};

export default ShopifyCollectionItems;
